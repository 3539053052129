<template>
  <div>
    <heade currentrStr="schedule" ></heade>

    <div class="plan common-width">
      <div class="plan-title">2022 Schedule</div>
      <div class="plan-table">
        <p>Past rounds</p>
        <a-table bordered :columns="columns" :data-source="data" :pagination="false">
          <!-- <a slot="name" slot-scope="text">{{ text }}</a> -->
          <span slot="customTitle"> Round</span>
          <!-- <span slot="tags" slot-scope="tags">
          <a-tag
            v-for="tag in tags"
            :key="tag"
            :color="
              tag === 'loser'
                ? 'volcano'
                : tag.length > 5
                ? 'geekblue'
                : 'green'
            "
          >
            {{ tag.toUpperCase() }}
          </a-tag>
        </span> -->
          <span slot="action">
            <a class="ant-dropdown-link" @click="look('./view')">
              View round →</a
            >
            <!-- <a>Invite 一 {{ record.name }}</a>
          <a-divider type="vertical" />
          <a>Delete</a>
          <a-divider type="vertical" />
          <a class="ant-dropdown-link"> More actions <a-icon type="down" /> </a> -->
          </span>
        </a-table>
      </div>
    </div>
  </div>
</template>
<script>
const columns = [
  {
    dataIndex: "name",
    key: "name",
    slots: { title: "customTitle" },
    scopedSlots: { customRender: "name" },
  },
  {
    title: "Age",
    dataIndex: "age",
    key: "age",
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "Tags",
    key: "tags",
    dataIndex: "tags",
    scopedSlots: { customRender: "tags" },
  },
  {
    title: "Action",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];

const data = [
  {
    key: "1",
    name: "John Brown",
    age: 32,
    address: "New York No. 1 Lake Park",
    tags: ["nice", "developer"],
  },
  {
    key: "2",
    name: "Jim Green",
    age: 42,
    address: "London No. 1 Lake Park",
    tags: ["loser"],
  },
  {
    key: "3",
    name: "Joe Black",
    age: 32,
    address: "Sidney No. 1 Lake Park",
    tags: ["cool", "teacher"],
  },
];
 import heade from '@/components/header.vue'
export default {
  data() {
    return {
      data,
      columns,
    };
  },
  components:{
    heade
  },
  mounted() {

  },
  methods: {
    look(url) {
      this.$router.push({ path: url });
    },
  },
};
</script>
<style scoped>
.plan {

 /* max-width: 1240px;
  margin: 0 auto; */
  text-align: left;
  padding: 30px 0;
}
.plan-title {
  font-size: 30px;
}
.plan-table {
  padding-top: 30px;
}
.plan-table p {
  font-size: 20px;
}
</style>